<template>
    <div class="container-xxl flex-grow-1 container-p-y" v-if="!isLoading">
        <div class="row">
            <div class="col-12 col-md-12 mb-4">
                <div class="card">
                    <div class="card-header d-flex justify-content-between flex-wrap mb-0 pb-1 ">
                        <div>
                            <small>PROJEKT</small>
                            <h5 style="min-height: 20px;">
                                {{ project.project_shortname }}
                            </h5>
                        </div>
                        <div>
                            <router-link :to="'/project/' + id + '/edit'" class="btn btn-outline-primary me-2 f-cap">
                                Edytuj projekt
                            </router-link>
                        </div>
                    </div>

                </div>

            </div>
            <ProjectNavComponent :project_id="id" value="calendar"></ProjectNavComponent>
            <div class="col-12">
                <div class="card ">
                    <div class="card-body">
                        <FullCalendar :options="calendarOptions" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import api from '../../api/api';
import ProjectNavComponent from './components/ProjectNavComponent.vue';

export default {
    components: {
        FullCalendar,
        ProjectNavComponent
    },
    data() {
        return {
            isLoading: true,
            calendarOptions: {
                plugins: [dayGridPlugin],
                initialView: 'dayGridMonth'
            }
        }
    },
    methods: {
        uploadSuccess: function () {
            this.getProjectFile();
        },
        getProject: function () {
            this.isLoading = true;

            api.getProject(this.id).then(response => {
                this.project = response.data
                this.isLoading = false;
            });
        },

    },
    created() {
        this.id = this.$route.params.id;
        this.getProject();
    }
}
</script>